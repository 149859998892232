







































































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {Member, XBan} from '~/components/data-class/data-class'
import VueQrReader from '~/components/vue-qr-reader.vue'
import PhoneWts from '~/components/phone-wts.vue'
import {MemberPermission} from '~/utils/permissions'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import moment from 'moment'
import {userPropicURL} from '~/utils/misc'

@Component({
    components: {
        PhoneWts,
        VueQrReader,
    },
    metaInfo() {
        return {
            title: 'Member List'
        }
    }
})
export default class MemberList extends mixins(QueryParserMixin, PaginationMixin) {
    static permission = MemberPermission.ViewMemberData

    members: Member[] = []

    keyword = ''
    role = ''
    bannedOnly = false
    loading = false

    queryDef = [
        {localVar: 'keyword'},
        {localVar: 'role'},
        {localVar: 'bannedOnly', queryStr: 'ban', type: QueryType.Boolean},
        {localVar: 'currentPage', queryStr: 'page', type: QueryType.Number},
    ]

    step = 20

    created() {
        this.parseQuery()
        this.getMembers()
    }

    async getMembers() {
        const param: { [key: string]: string } = {
            skip: (this.currentPage - 1) * this.step + '',
            number: this.step + '',
            xbanned: this.bannedOnly + ''
        }

        if (this.keyword) param.keyword = this.keyword
        if (this.role) param.role = this.role
        this.loading = true

        try {
            const res = await createRequest('/members', 'get', param).send()
            this.members = res.data.members
            this.totalCount = res.data.count
            this.setQuery()
        } catch (e) {
            console.error(e)
            this.$message.error('Error')
        }
        this.loading = false
    }

    // Page
    async toPage(pageNum) {
        this.currentPage = pageNum
        this.getMembers()
    }

    propicURL(memberId) {
        return userPropicURL(memberId, 100)
    }

    dateTimeStr(rawStr: string) {
        let str = rawStr || ''

        function strIns(idx: number, val: string) {
            str = str.slice(0, idx) + val + str.slice(idx)
        }

        strIns(12, ':')
        strIns(10, ':')
        strIns(8, ' ')
        strIns(6, '/')
        strIns(4, '/')
        return str
    }

    dateStr(rawStr: string) {
        let str = rawStr || ''

        function strIns(idx: number, val: string) {
            str = str.slice(0, idx) + val + str.slice(idx)
        }

        strIns(6, '/')
        strIns(4, '/')
        return str
    }

    bannedMsg(xban: XBan) {
        return 'Banned ' + (xban.days >= 999 ? 'forever' : `till ${moment(xban.until).format('YYYY/MM/DD')}`)
    }
}
